.modal-backdrop {
  z-index: 999 !important;
}

.buy-product-modal .detail-product-modal .issuer-modal {
  .modal-dialog {
    max-width: 1000px !important;
    max-height: 700px !important;

    .modal-content {
      width: 1000px !important;
      height: 700px !important;
      overflow: auto;
    }
  }
}

// .modal-content {
//   overflow-y: auto;
//   max-height: 700px;
// }

.info-payment-popup {
  .modal-content {
    max-height: 814px;
  }
}

.view-product-modal {
  .modal-content {
    width: 500px;
  }
}

#create-ctv {
  .modal-dialog {
    max-width: 724px;
    width: 100%;

    label {
      color: #1F1F1F;
      font-size: 14px;
      font-family: 'OpenSans6';
    }
  }
}

@media (min-width: 1024px) {}

@media (min-width: 450px) {
  .sell-product-modal {
    .modal-dialog {
      width: 425px !important;
    }

    // .modal-body {
    //   min-height: 495px;
    // }
  }


}


.otp-order-label {
  .otp {
    padding: 0;
    display: block !important;
  }

  .otp-container_form {
    box-shadow: none !important;
    max-width: 100% !important;
  }
}

.edit-agency-modal {
  .modal-dialog {
    max-width: 724px;
  }

  .modal-content {
    max-height: 900px;
  }
}

.edit-organization-modal {
  .modal-dialog {
    max-width: 724px;

    .modal-title {
      font-family: 'OpenSans6';
      font-size: 24px;
    }
  }

  // .modal-content {
  //   max-height: 700px;
  // }
}

.approve-agency-modal {
  .modal-dialog {
    max-width: 1200px;

    .modal-content {
      border: none;
      border-radius: 15px;

      .modal-header {
        .close {
          position: absolute;
          right: 15px;
          top: 20px;
        }
      }
    }
  }
}

@media (min-width: 450px) {
  .otp-notice-not-register {
    .modal-content {
      min-width: 430px;
    }
  }
}

@media (min-width: 1200px) {
  .info-payment-popup {
    .modal-dialog {
      min-width: 891px;
      // min-width: 1200px;
    }

    // .modal-content {
    //   width: 1200px;
    // }
  }
}

.confirm-popup-modal,
.success-popup-modal,
.warning-popup-modal,
.error-popup-modal,
.result-trial-modal {
  z-index: 1061 !important;

  .modal-dialog {
    max-width: 545px;
    top: 30%;

    .modal-content {
      border: none;
      border-radius: 8px;
    }

    .modal-header {
      border: none;
      padding: 30px 30px 0 30px;

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;


        img {
          width: 26px;
          height: 26px;
        }

        &-text {
          font-size: 20px;
          font-family: 'OpenSans6';
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
    }

    .modal-body {
      padding: 15px 30px 15px;

      &--message {
        white-space: normal;
        font-size: 16px;
        line-height: 26px;
      }
    }

    .modal-footer {
      border: none;
      padding: 0 30px 30px 30px;

      .row {
        margin: 0px;
        border-top: 1px solid rgba(224, 224, 224, 1);

        div {
          padding: 15px;
        }
      }

      .btn {
        height: 42px;
        cursor: pointer;
      }

      .border-right {
        border-right: 1px solid rgba(224, 224, 224, 1) !important;
      }

      .btn-ok {
        border-radius: 25px;
        background: #008561;
        color: var(--white);
        width: auto;
      }

      .btn-close {
        color: #2F80ED;
      }
    }
  }

}

.btn-close {
  color: rgba(47, 128, 237, 1);
  cursor: pointer;
}

.input-reason-reject-modal {
  z-index: 1061 !important;

  .modal-dialog {
    max-width: 400px;
    top: 10%;
  }
}

.view-image-modal {
  z-index: 1061 !important;

  .modal-dialog {
    // top: 10%;
    max-width: 700px;
  }
}

.large-z-index-backdrop {
  z-index: 1060 !important;
}

.pick-color-modal {
  .modal-dialog {
    max-width: 230px;
  }
}
