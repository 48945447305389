@import "../global/font";
@import "../global/button";

body {
  font-family: OpenSans4;

  background-color: #f7f7f7;
  color: #000;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.hidden {
  display: none !important;
}

.main {
  background: #F7F7F7;
  // min-height: calc(100vh + 40px);
  padding-top: 40px;
}

input {
  border-radius: 5px !important;
  border: 1px solid var(--components-border-color, #E5E5E5);
  background: #FFF;

  &:hover,
  &:focus {
    border-color: #008561 !important;
  }

  &:disabled:hover {
    border-color: #E0E0E0 !important;
  }
}

.disabled {
  background-color: #eceeef !important;
  pointer-events: none !important;
  cursor: not-allowed;
}

button,
button:focus,
button:focus-visible {
  outline: none;
}

h2 {
  color: #202D41;

  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

h3 {
  color: #202D41;

  font-size: 20px;
  font-style: normal;
  font-family: "OpenSans7";
}


.common {
  &__title {
    color: #1F1F1F;
    font-size: 24px;
    font-family: 'OpenSans6';
  }

  &__subtitle {
    color: #202D41;
    font-size: 18px;
    font-style: normal;
    font-family: "OpenSans7";
  }

  &__timeFilter {
    display: flex;
    align-items: center;

    margin-top: 30px;
    margin-bottom: 20px;

    .from-date,
    .to-date {
      position: relative;
      margin-left: 15px;

      margin-bottom: 0;

      label {
        position: absolute;
        top: 7px;
        left: 4px;
      }

      input {
        width: 230px;
        padding-left: 5rem;
      }
    }
  }

  &__table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #f2f2f2;

    table {
      thead {
        tr {
          th:not(:last-child) {
            border-right: 1px solid #f2f2f2;
          }
        }
      }

      tbody {
        tr.first-child {
          td {
            background: var(--Gray-6, #F2F2F2) !important;
          }
        }
      }

      tr {
        &:hover {
          td .file__status--name {
            background: #EEFAFE !important;
          }
        }

        th {
          border: none;
          border-right: 1px solid #ffffff;

          background: #F4FFFB;
          color: #1A9365;

          white-space: nowrap;

          font-size: 14px;
          font-family: 'OpenSans6';

          vertical-align: middle;

          &:first-child:not(.sub__th) {
            border-top-left-radius: 10px;
          }

          &:last-child:not(.sub__th) {
            border-top-right-radius: 10px;
            border-right: none;
          }

          &.full__th {
            border-bottom: 1px solid #ffffff;
            text-align: center;
          }
        }

        td {
          background: #ffffff;
          font-size: 14px;
          vertical-align: middle;

          input,
          .dropdown-display-value {
            height: 28px;
            font-size: 14px;
          }

          .dropdown-display-value::after {
            content: "";
            width: 8px;
            height: 8px;
            top: 7px;
          }
        }

        &:hover {
          td {
            background: #96E2CE;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__btn {
    border-radius: 25px;
    background: #008561;
    color: $white;
    height: 46px;
    ;
    width: 100%;
  }

  &__line {
    background: #E0E0E0;
    height: 8px;
  }
}


.input-group-prepend {
  margin-right: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.group__icon {
  position: relative;

  input {
    padding-left: 38px;
    max-width: 459px;
    width: 100%;
  }

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

//Color
.green {
  color: #27AE60;
}

.red,
.required {
  color: var(--Red, #EB5757);
}

.orange {
  color: #ED7F1D;
}

.blue {
  color: #56CCF2;
}

.gray {
  color: #828282;
}

.btn.disabled,
.btn:disabled {
  opacity: .5;
}

.common__timeFilter {

  .dropdown-display-value {
    background-color: $white !important;
  }
}

.table__filter {
  .dropdown-display-value {
    background-color: $white !important;
    border: 1px solid rgba(0, 0, 0, .15);

    span {
      font-size: 12px !important;
    }

    &::after {
      width: 8px !important;
      height: 8px !important;
    }
  }
}

/* Define Scroll Broser */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f2f2f2;
  background-color: #dbdbdb;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  border: 2px solid #BDBDBD;
  border-radius: 5px;
}

//Custom checkmark
.labelRadio {
  display: inline-block;
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 16px;
  white-space: nowrap;
  line-height: 2;
}

.labelRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.labelRadio:hover input~.checkmark {
  background-color: #ccc;
}

.labelRadio input:checked~.checkmark {
  background-color: $white;
  border-color: $blue;
}

.labelRadio input:checked~.checkmark:after {
  display: block;
}

.labelRadio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $base-blue;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid #727272;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.icon-add {
  display: flex;
  align-items: center;
  color: rgba(47, 128, 237, 1);
  cursor: pointer;
}

.btn__delete--span {
  position: absolute;
  top: -17px;
  right: -15px;
  cursor: pointer;
}

.input-group {
  position: relative;

  textarea {
    border-radius: 5px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
  }

  span {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 8px;
    font-size: 14px;

    &.input-group-text {
      position: relative;
      top: unset;
      right: unset;

      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: .375rem .75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }
  }

  &_text:first-child {
    right: unset;
    left: 10px;
  }

  .text-area {
    bottom: 5px;
    top: unset;

    color: var(--Gray-4, #BDBDBD);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.217px;
  }
}

.container__nav {
  border-top: 1px solid var(--Gray-6, #E0E0E0);
  background: #ffffff;
  height: 60px;

  display: flex;
  align-items: center;

  ul {
    border-bottom: none;

    li {
      margin-left: 30px;

      &:first-child {
        margin-left: 0;
      }

      a {
        border: none;
        color: var(--Gray-4, #BDBDBD);

        font-size: 14px;
        font-family: 'OpenSans6';
      }

      a.active,
      a:hover {
        border: none;
        color: var(--Gray-4, #1F1F1F);
        ;
      }
    }
  }
}

.text-ellips {
  width: 300px !important;
  max-height: 87px !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-link {
  cursor: pointer;
  color: #2F80ED !important;
}

.financial-plan,
fee-dashboard,
agency-tabs-list {
  display: block;
  min-height: calc(100vh - 180px);
}

table {
  auto-complete {
    input {
      height: 28px;
    }
  }
}

withdrawal-fee-list {
  .dropdown-display-value {
    min-width: 180px !important;
  }
}

normal-plan-masterdata {
  .common__btn {
    max-width: 156px;
    width: 100%;
  }
}

.cke_notification {
  display: none !important;
}

.front-drop {
  background: #00000099;
  border-radius: 5px;
  width: 150px;
  height: 110px;

  position: absolute;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 0px;
  opacity: 0;
  transition: transform 250ms;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;

  &:hover {
    opacity: 1;
  }

}

#dropdownMenuButton {
  padding: 5px;
  font-size: 14px;
}

.group__item {
  display: flex;
  flex-direction: column;

  span {
    color: #202D41;
    line-height: 20px;

    &:first-child {
      font-size: 16px;
      font-family: 'OpenSans6';
    }

    &:last-child {
      font-size: 14px;
      font-family: 'OpenSans4';
    }
  }
}


.stt {
  border-radius: 25px;
  padding: 5px 10px;

  font-size: 12px;
  font-weight: 16px;
  font-family: 'OpenSans6';

  &.blue {
    background: #EEFAFE;
    color: #56CCF2;
  }

  &.green {
    background: #E2F0E8;
    color: #27AE60;
  }

  &.red {
    background: #FFE9E9;
    color: #EB5757;
  }
}

table {
  tr {
    td {
      input {
        font-size: 14px;
      }

      .dropdown-display-value {
        span {
          font-size: 14px !important;
        }
      }
    }
  }
}

.icon-add {
  font-family: 'OpenSans6';
  font-size: 14px;

  display: flex;
  align-items: center;
  gap: 5px;
}

.basic__info {
  display: flex;
  flex-direction: column;

  span {
    color: #202D41;

    font-size: 14px;
    font-style: normal;
    font-family: 'OpenSans6';
    ;

    line-height: 22px;

    &:not(:last-child) {
      font-family: 'OpenSans6';
    }
  }

  p {
    color: var(--Gray-3, #828282);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    margin-bottom: 0;
  }
}

.trial-agency-list {
  tr {
    &:hover {
      td {

        .keyboard-input {
          background: #96E2CE;

        }
      }
    }
  }
}

.block__input {
  input-number {
    input {
      border-radius: 0px 5px 5px 0px !important;
      border-color: #E0E0E0;
    }
  }
}

.active {
  form-input-evaluation-risk-survey {
    .form__input {
      background: transparent !important;
    }
  }
}

.credit-questionaire {
  color: #202D41;
  font-family: "OpenSans7";
  font-size: 18px;
}


//Toggle Switch
.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 48px;
  height: 24px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;

  &:before,
  &:after {
    content: "";
  }

  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    left: 4px;
    transition: left 0.25s;
  }

  .toggle:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .toggle-checkbox:checked+& {
    background: #27AE60;

    &:before {
      left: 26px;
    }
  }
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
