/* Variable */
:root {
  // dynamic config
  --white: #fff;
  --primary-color: #0368FF;
  --hover-color: #00F0FF;
  --default-button-color: #999;
  --primary-button-color: #0368FF;
  --success-button-color: #27AE60;
  --warning-button-color: #ffd235;
  --danger-button-color: #da251c;
  --font-color: #333333;
  --background-url: url(/assets/images/BG.png) no-repeat top;
  --light-blue: #0053BF;
  --blue-btn-link: #2D9CDB;
  --txt-blue: #0F3256;
  --green: #1A9365;
  --dark-green: #064919;
  --black: #333333;
  --table-bg: #F5F9FF;
  --input-border: #E0E0E0;
  --status-color: #566D7E;
  --light-gray: #6D6D6D;
  --gray: #494F58;
  --background-grey: #f2f2f2;
  --light-gray-border: #E3E9F6;
  --tab-text: #828282;
  --tint-border: #f2f2f2;
  --table-border: #EDF2FA;
  --light-gray: #30363D;
  --dark-gray: #333;
  --dusty-gray: #999;
  --red: #da251c;
  --orange: #ffab00;
  --blue-link: #2F80ED;
}

$white : var(--white);
$base-blue: var(--primary-color);
$black : var(--font-color);
$default-button : var(--default-button-color);
$primary-button : var(--primary-button-color);
$success-button : var(--success-button-color);
$warning-button : var(--warning-button-color);
$danger-button : var(--danger-button-color);
$orange-btn : var(--warning-button-color);
$orange-register: var(--warning-button-color);
$orange : var(--warning-button-color);
$backgroundUrl: var(--background-url);
$light-blue: var(--light-blue);
$hover-menu: var(--hover-color);
$blue-btn-link: var(--blue-btn-link);
$blue : var(--primary-color);
$dark-blue : var(--primary-color);
$txt-blue : var(--txt-blue);
$green : var(--green);
$dark-green : var(--dark-green);
$table-bg: var(--table-bg);
$background-grey: var(--background-grey);
$input-border: var(--input-border);
$status-color: var(--status-color);
$light-gray : var(--light-gray);
$gray : var(--gray);
$light-gray-border: var(--light-gray-border);
$tab-text: var(--tab-text);
$tint-border: var(--tint-border);
$light-gray: var(--light-gray);
$dark-gray : var(--dark-gray);
$dusty-gray : var(--dusty-gray);
$red : var(--red);
$blue-link: var(--blue-link);
$orange: var(--warning-button-color);

/* Font */
$root : 18;

$inp_height: 48px;
$inp_height_m: 38px;
$font-family: 'Roboto',
sans-serif;

$toolbar-height: 56px;
$horizontal-menu-height: $toolbar-height;

$sidenav-width: 250px;
$sidenav-user-block-height: 156px;
$mini-sidenav-user-block-height: 70px;

$flex-distance: 8px;
$inner-sidenav-content-padding: $flex-distance;

//Login, Register, Errors box
$box-height: 186px;
$box-position: 34px;
$register-box-height: 300px;
$breakpoints: (phone-sm : 420px,
  phone-lscape : 812px,
  // tablet-sm : 834px,
  tablet-sm : 768px,
  tablet-lscape : 1023px,
  laptop : 1440px,
  desktop : 1920px);
