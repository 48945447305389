@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #navbar .nav-item {
    margin-left: 0px;
  }

  #nav-authen {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .search-form {
    .row>div:not(:first-child) {
      padding-left: 0;
    }
  }

  .perfect-acc {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {

  .fa {
    font: normal normal normal 14px/1 FontAwesome !important;
  }

  .col-one,
  .col-two,
  .col-three {
    margin-top: 1rem;

    p {
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  .col-four {
    p {
      font-size: 1rem;
    }
  }

  .table thead {
    th {
      white-space: nowrap;
    }
  }

  // .content-search {
  //   padding-top: 15px;
  // }
  /* Add horizontal scroll portfolio detail navbar */
 

  .copys {
    position: relative;

    .btn-copy,
    .btn-copy2 {
      position: absolute;
      right: 18px;
      bottom: 6px;
      padding: 0.35rem 0.5rem;
    }

    .btn-copy2 {
      bottom: 10px;
    }
  }
}


@media only screen and (min-width: 480px) {

  .paid {
    display: inline-block;
  }

  .paid-icon {
    display: none;
  }
}

@media only screen and (max-width: 480px) {

  //Common
  .m-top0 {
    margin-top: 15px !important;
  }

  //Modal
  .modal-dialog {
    width: 95% !important;
  }

  .modal--style2,
  .modal--style1 {
    min-width: 0px !important;
  }

  .header-search {
    .search-form .btn-header-search {
      margin-bottom: 0px;
      font-size: 1.5rem;
    }
  }

  .no-items {
    width: 100px;
    height: 100px;
  }

  .totals {
    margin-top: 15px;
  }

  .sum-padding {
    padding-left: 0;
  }

  .table-style {
    tbody {
      .btn {
        margin: 0 auto;
      }
    }
  }

  .mb-pd-left {
    padding-left: 7.5px;
  }

  .mb-pd-right {
    padding-right: 7.5px;
  }

  .table-style,
  .table-style-2 {
    .table {
      td {
        white-space: nowrap;
      }

      td,
      th {
        padding: 0.75rem .5rem;
      }
    }
  }

  .text-area {
    &.form-control {
      padding-bottom: 4.2rem;
    }
  }


  .hidden-down {
    display: none !important;
  }

  .fix-height {
    height: auto;
  }

  .hidden-cols {
    display: none !important;
  }

  .table {

    th.mobile-col,
    td.mobile-col {
      padding: 0.5rem 0;
    }

    th.wrap-text,
    td.wrap-text {
      white-space: normal;
    }
  }

  .table-style-sum {
    .total-sum {
      width: 80px;
    }
  }

  .mobile-table {
    table-layout: fixed;

    th,
    td {
      padding: 0.75rem 0.15rem !important;

      .btn--edit {

        // right: -9px;
        img {
          width: 12px;
        }
      }
    }

    &_col {
      width: 25%;
    }
  }

  .td-align-number {
    text-align: right !important;
  }

  .info-img {
    height: 140px !important;
    width: 140px !important;
  }

  .info-img,
  .info-titles {
    display: inline-block !important;
    margin: 0 auto;
    float: none !important;
  }

  #header {
    padding-top: 10px;
  }

  .mobile-em {
    display: inline-block !important;
  }

  .pencil {
    .btn--edits {
      left: -7px !important;
    }
  }

  .login-input {
    font-size: 1.2rem !important;
    height: 50px !important;
  }

  .pre-line {
    strong {
      span {
        font-size: 4rem;
      }
    }
  }

  .btn--fix {
    width: 100%;
    height: 36px;
    font-size: 17px;
  }

  // phong code 4/6/2021
  .dropdown-display-value {
    min-width: 0 !important;
  }

  .heading-title {
    white-space: pre-wrap !important;
  }
}

@media (max-width: 812px) {
  // .issuer-column {
  //   width: 135px !important;
  // }
}

@media (max-width: 767px) {

  // phong code 4/6/2021
  .dropdown-display-value {
    min-width: 120px !important;
  }

  input,
  .input-group-addon,
  .form-control,
  #soflow {
    height: $inp_height_m !important;
  }

  input.file-upload {
    height: 100% !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: inherit;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hidden-column {
    display: none;
  }

  .login {
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {


  .table {
    td {
      white-space: nowrap;

      .btn {
        font-size: 0.9rem !important;
      }
    }
  }

  .tb-padding {
    &--left {
      padding-left: 10px;
    }

    &--right {
      padding-right: 10px;
    }
  }

  .tb-margin {
    &--top {
      margin-top: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .align-middle {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .copys {
    display: flex;
    align-items: center;
  }

  .modal-sm {
    max-width: 390px;
  }

  .table__title--filter {
    padding-left: 210px;
  }
}

@media only screen and (max-width: 768px) {
  .hidden-ipad {
    display: none;
  }

  #focusEmail {
    font-size: 15px;
  }

  .dropdown-content {
    span {
      font-size: 1.2rem !important;
    }
  }

  .canvas-container {
    height: auto !important;
    overflow: hidden;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 569px) {
  .modal--style2 {
    min-width: 450px !important;
    max-width: 90%;
  }

  .horizon-mobile {
    max-height: calc(100vh - 180px);
    overflow-y: auto;

    +.modal-footer {
      box-shadow: 0px -3px 4px -2px rgba(0, 0, 0, 0.37);
      z-index: 3;
    }
  }

  .tb-padding--left {
    padding-left: 7.5px;
  }

  .tb-padding--right {
    padding-right: 7.5px;
  }

  .align-middle {
    align-items: center;
    display: flex;
    margin: 0 auto;
    margin-top: 1.3rem;
  }

  .info-img {
    height: 160px !important;
    width: 160px !important;
  }
  .table__title--filter {
    padding-left: 210px;
  }

  .search-form .dropdown .dropdown-display-value::after {
    top: 5px;
  }
}

@media only screen and (max-width: 812px) and (min-width: 569px) {


  .sub-content {
    h5 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
}

/* Fix Iphone 5 */

@media only screen and (max-width: 320px) {

  .btn-buy,
  .btn-cancel {
    margin: 0 2px;
  }

  .form-group .bootstrap-select .dropdown-toggle {
    padding-left: 0px;
  }

  #nav-authen li a {
    font-size: 0.72rem;
    padding: 0.4rem 0.4rem;
  }


  .title-child {
    font-size: 2.4rem;
  }

  .tb-padding--left {
    padding-left: 15px !important;
  }

  .tb-padding--right {
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 992px) {

  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: absolute;
    float: none;
  }

}

@media only screen and (min-width: 320px) and (max-width: 660px) {

  .search-form .dropdown .dropdown-display-value::after {
    content: "";
    width: 10px;
    height: 10px;
    top: 10px;
    right: 17px;
  }

  .dropdown-display-value {
    height: 38px !important;
  }

  .modal-dialog {
    .modal-contents {
      // max-height: 500px;
    }

    .modal-body {
      max-height: 350px;
      overflow-y: auto;
      margin-bottom: 0;
    }
  }

  .table-responsive {
    table {
      max-width: none !important;
    }
  }
}

@media (min-width: 768px) {
  .dropdown-on {
    overflow: inherit;
  }
}


@media (max-width: 425px) {
  .transaction {
    .container {
      padding: 0px;

      .row {
        margin: 0;

        .full-width {
          padding: 5px;
        }
      }
    }
  }

  .border-th .dropdown .dropdown-display-value::after {
    right: -5px !important;
  }

  .dropdown-content {
    span {
      font-size: 1rem !important;
    }
  }
}

@media only screen and (max-width: 1024px) {

  .table-style .table td,
  .table-style-2 .table td {
    padding: 0.75rem 0.5rem;
  }

  .tab-content {
    .right-content {
      .guildline {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-height: 450px) {
  .modal-dialog>.modal-content {
    margin-top: 80px;
  }

  .modal-header {
    .close {
      top: 77px !important;
    }
  }
}


@media screen and (min-width: 1300px) {
  .container {
    max-width: 100%;
    width: 1290px;
  }
}
